/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Study</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/studies'
        icon='abstract-28'
        title='Studies'
        fontIcon='bi-layers'
      />  
      <SidebarMenuItem
        to='/analysis'
        icon='abstract-26'
        title='Analysis'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/users'
        title='User Management'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
        to='/admin'
        title='Admin'
        icon='abstract-22'
        fontIcon='bi-person'
      ></SidebarMenuItem>
      </SidebarMenuItem>
    </>
  )
}

export {SidebarMenuMain}
